// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1100px;
$xxl: 1366px;

// Colors
$color-white: #fff;
$color-black: #000;
$color-green-bright: #DDFD41;
$color-green-dark: #0e291a;
$color-green-map-bg: #1C3728;
$color-blue: #1A6D93;
$color-brown: #996E52;
$color-brown-dark: #4e392b;
$color-gray-dark: #3F3F3F;
$color-gray-light: #999A9A;
$color-red-orange: #E66C46;
$color-tan: #DED0B2;
$color-tan-light: #f0ede8;

$color-testing: rgba(255, 0, 255, 0.2);

// z-index
$z-index-modal: 1100;
