@import 'styles/common';

.HomeStartExploring {
  position: relative;
  height: 264vw;
  padding: 14.2vw 0 0 0;
  text-align: center;
  // background-color: $color-testing;

  h2 {
    width: 60vw;
    margin: 0 auto 8vw;
    line-height: 1.07;
    color: $color-tan;
  }
}

.Badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: 26vw;
    margin: 0 3vw 5vw;
    transition: opacity 300ms ease;
  }
}
.BadgesAcquiredBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 4.4vw;
  margin: 0 0 6vw;
  font-size: 2.2vw;
  color: $color-tan;
  text-transform: uppercase;
  @include font-graphik-bold;

  div {
    flex: 1;
    height: 0.5vw;
    background-color: $color-green-bright;

    &:first-child {
      margin-right: 3vw;
    }
    &:last-child {
      margin-left: 3vw;
    }
  }
  span {
    color: $color-green-bright;
  }
}

.MapContainer {
  position: relative;
  width: 100vw;
  height: auto;
  margin: 0 auto;
}
.Map {
  display: block;
  width: 100%;
  height: auto;
}
.MapExpand {
  position: absolute;
  right: 5vw;
  bottom: 4vw;

  img {
    display: block;
    width: 6vw;
    height: auto;
  }
}
.MapPin {
  position: absolute;
  top: 0;
  left: 0;
}
.MapPinInner {
  position: relative;

  img {
    $pin-width: 3.5vw;
    display: block;
    width: $pin-width;
    height: (189.55 / 168.1) * $pin-width;
  }
  div {
    $animation-offset: 500ms;
    $circle-size: 12vw;
    position: absolute;
    top: -2.2vw;
    left: -3.3vw;
    display: block;
    width: $circle-size;
    height: $circle-size;
    background-color: transparent;
    border: 3px solid $color-green-bright;
    border-radius: $circle-size;
    animation-duration: ($animation-offset * 5);
    animation-name: mapPinCircle;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @for $i from 1 through 5 {
      &:nth-child(#{$i + 1}) {
        animation-delay: $i * -1 * $animation-offset;
      }
    }
  }
}
@keyframes mapPinCircle {
  0% {
    transform: scale(0.0);
    opacity: 1;
  }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}

.ButtonContainer {
  // margin: 7.7vw 0 0;
  text-align: center;
}
