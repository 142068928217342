@import 'styles/common';

.Home {

}
.HomeContent {
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}
