// Font Faces


// SantiRey-GazpachoHeavy
@font-face {
  font-family: 'Gazpacho Heavy';
  src: url('../assets/fonts/SantiRey-GazpachoHeavy.woff2') format('woff2'),
    url('../assets/fonts/SantiRey-GazpachoHeavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

// MyriadPro-Regular
@font-face {
  font-family: 'MyriadPro Regular';
  src: url('../assets/fonts/MyriadPro-Regular.woff2') format('woff2'),
    url('../assets/fonts/MyriadPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Graphik
@font-face {
  font-family: 'Graphik Regular';
  src: url('../assets/fonts/Graphik-Regular.woff2') format('woff2'),
    url('../assets/fonts/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik Bold';
  src: url('../assets/fonts/Graphik-Bold.woff2') format('woff2'),
    url('../assets/fonts/Graphik-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik Wide Black';
  src: url('../assets/fonts/GraphikWideBlack.woff2') format('woff2'),
    url('../assets/fonts/GraphikWideBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
