@import 'styles/common';

.Button {
  display: inline-block;
  min-width: 50vw;
  padding: 2.4vw 2.9vw;
  font-size: 4.5vw;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms ease;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  border: 1vw solid $color-black;
  @include font-graphik-wideblack;

  &:active {
    transition: background-color 100ms ease;
  }
}

// Themes
.bright-green {
  color: $color-black;
  background-color: $color-green-bright;
  border-color: $color-green-bright;

  &:active {
    color: $color-green-bright;
    background-color: transparent;
  }
}
.red-orange {
  color: $color-white;
  background-color: $color-red-orange;
  border-color: $color-red-orange;

  &:active {
    color: $color-red-orange;;
    background-color: transparent;
  }
}
