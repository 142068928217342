@import 'styles/common';

.HomeHero {
  position: relative;
  height: 82vw;
  padding: 8vw 0 0 4.7vw;
  // background-color: $color-testing;

  h1 {
    width: 50vw;
    color: $color-green-dark;
  }
  p {
    width: 40vw;
    margin-top: 2.1vw;
    font-size: 2.6vw;
    line-height: 1.3;
    color: $color-brown;
    text-transform: uppercase;
    @include font-graphik-bold;
  }
}
.Sticker {
  position: absolute;
  top: 63.5vw;
  left: -1.3vw;
  display: block;
  width: 32vw;
  height: auto;
}
