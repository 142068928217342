.modal-portal {
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}
.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-modal;
  padding: 0;
  opacity: 0;
  transition: opacity 600ms ease-in-out;
  background-color: rgba(0,0,0, .9);
}
.modal-content {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  outline: none;
  background-color: $color-white;

  &.full-width {
    width: 100%;
  }
  &.full-height {
    height: 100%;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3.5vw 4.5vw;
    outline: 0;
    z-index: 100;
    appearance: none;
    background: transparent;
    border: 0;
    outline: 0;

    img {
      display: block;
      width: 6vw;
      height: auto;
    }
  }
  .qr-reader {
    width: 100vw;
    height: 100vw;
    min-height: 100vh;
    min-width: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    video {
      object-fit: cover;
    }
  }
}
