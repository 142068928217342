// Font Mixins
@mixin font-gazpacho-heavy {
  font-family: 'Gazpacho Heavy', sans-serif;
  font-weight: 800;
  font-style: normal;
}
@mixin font-myriad-regular {
  font-family: 'MyriadPro Regular', sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin font-graphik-wideblack {
  font-family: 'Graphik Wide Black', sans-serif;
  font-weight: 900;
  font-style: normal;
}
@mixin font-graphik-regular {
  font-family: 'Graphik Regular', sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin font-graphik-bold {
  font-family: 'Graphik Bold', sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Responsive
@mixin respond-to($breakpoint) {
  @if $breakpoint == "sm" {
    @media only screen and (min-width: $sm) {
      @content;
    }
  }
  @else if $breakpoint == "md" {
    @media only screen and (min-width: $md) {
      @content;
    }
  }
  @else if $breakpoint == "lg" {
    @media only screen and (min-width: $lg) {
      @content;
    }
  }
  @else if $breakpoint == "xl" {
    @media only screen and (min-width: $xl) {
      @content;
    }
  }
  @else if $breakpoint == "xxl" {
    @media only screen and (min-width: $xxl) {
      @content;
    }
  }
  @else if $breakpoint == "mobile-only" {
    @media only screen and (max-width: $md - 1) {
      @content;
    }
  } @else if $breakpoint == "tablet-only" {
    @media only screen and (min-width: $md) and (max-width: $lg - 1) {
      @content;
    }
  }
}
