/*****************************************
 * slidefade
 *****************************************/
$slidefade-duration: 600ms;
.slidefade,
.slidefade-enter,
.slidefade-exit-done {
  opacity: 0;
  transform: translateY(20px);
}
.slidefade-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity $slidefade-duration,
              transform $slidefade-duration;
}
.slidefade-enter-done,
.slidefade-exit {
  opacity: 1;
  transform: translateY(0px);
}
.slidefade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity $slidefade-duration,
              transform $slidefade-duration;
}
