* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-black;
}

body {
  @include font-graphik-regular;
}

a {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

h1,
h2 {
  font-size: 10.1vw;
  line-height: 0.95;
  @include font-gazpacho-heavy;
}
h3 {
  font-size: 6.1vw;
  line-height: 1.1;
  letter-spacing: 0.09vw;
  @include font-gazpacho-heavy;
}
