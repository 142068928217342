@import 'styles/common';

.HomeSearchToWin {
  position: relative;
  height: 54vw;
  padding: 9vw 0 0 0;
  text-align: center;
  // background-color: $color-testing;

  h2 {
    color: $color-green-dark;
  }
  p {
    padding: 0 9vw;
    margin: 2.6vw auto 0;
    font-size: 3.15vw;
    line-height: 1.2;
    letter-spacing: 0.01vw;
    // white-space: pre;
    color: $color-black;
  }
}
