@import 'styles/common';

.Header {
  display: block;
  width: 100%;
  height: 13.8vw;
  position: relative;
  background-color: $color-tan-light;
  padding-top: 3.3vw;
}
.Logo {
  $logo-width: 28vw;
  display: block;
  width: $logo-width;
  height: (57.16 / 228.82) * $logo-width;
  margin: 0 auto;
}
