@import 'styles/common';

.ModalBadgeAcheived {
  min-height: 100%;
  background-color: $color-tan-light;
  text-align: center;
  padding: 0 0 10vw;

  h2 {
    margin: 0 0 3.7vw;
  }
  p {
    margin: 0 auto;
    font-size: 3.3vw;
    line-height: 1.2;
    white-space: pre;
    letter-spacing: -0.07vw;
  }
  h3 {
    width: 70vw;
    margin: 0 auto 8vw;
  }
}
.Hero {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 0 5.5vw;
}
.Badge {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 6.4vw 5vw 7.8vw;

  img {
    display: block;
    width: 25vw;
    height: auto;
  }
}
