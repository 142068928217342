@import 'styles/common';

.HomeBottom {
  position: relative;
  padding: 6vw 0 11vw;
  // background-color: $color-testing;

  img {
    $image-width: 47vw;
    display: block;
    width: $image-width;
    height: (154.7 / 116.94) * $image-width;
    margin: 0 auto;
  }
}
