@import 'styles/common';

.Footer {
  display: block;
  width: 100%;
  position: relative;
  background-color: $color-tan-light;
  padding: 7vw 4.7vw 0;
  text-align: center;
}

.CallToAction {
  margin-bottom: 9vw;

  div {
    margin-bottom: 6vw;
    color: $color-green-dark;
    font-size: 7vw;
    line-height: 1.05;
    text-transform: uppercase;
    @include font-graphik-wideblack;
  }
}

.Divider {
  width: 100%;
  height: 2px;
  background-color: $color-brown;
}

.Bottom {
  padding: 6.5vw 0;
}
.Logo {
  $image-width: 42vw;
  display: block;
  width: $image-width;
  height: (57.16 / 228.82) * $image-width;
  margin: 0 auto 2vw;
}

.Social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vw;

  a {
    position: relative;
    display: inline-block;
    padding: 2vw 3.2vw;

    &:hover,
    &:active {
      img {
        &:last-child {
          opacity: 1;
        }
      }
    }

    img {
      display: block;
      transition: opacity 300ms ease;

      &:last-child {
        position: absolute;
        top: 2vw;
        left: 3.2vw;
        opacity: 0;
      }
    }
  }
}

$social-icon-width: 5vw;
.SocialIconInstagram {
  width: $social-icon-width;
  height: (56.53 / 56.57) * $social-icon-width;
}
.SocialIconTwitter {
  width: $social-icon-width;
  height: (46.17 / 56.57) * $social-icon-width;
}
.SocialIconFacebook {
  width: $social-icon-width;
  height: (1357 / 1365) * $social-icon-width;
}

.Links {
  margin-bottom: 2.8vw;
  text-align: center;

  a {
    display: block;
    margin-bottom: 1.8vw;
    font-size: 2.5vw;
    color: $color-brown;
    text-transform: uppercase;
    text-decoration: underline;
    letter-spacing: 0.1vw;
    transition: color 300ms ease;
    @include font-graphik-bold;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover,
    &:active {
      color: $color-brown-dark;
    }
  }
}

.Legal {
  font-size: 2.5vw;
  color: $color-brown;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  @include font-graphik-bold;

  span {
    margin: 0 2.5vw;
  }
}
