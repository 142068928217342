@import 'styles/common';

.DesktopNotice {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $color-tan-light;
}
.Notice {
  position: relative;
  width: 992px;
  padding-top: (9 / 16) * 992px;
  background-position: center;
  background-size: 992px auto;
  background-repeat: no-repeat;

  @include respond-to('xl') {
    width: 1100px;
    padding-top: (9 / 16) * 1100px;
    background-size: 1100px auto;
  }
}
.Content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px 0 0;
  text-align: center;

  @include respond-to('xl') {
    padding: 18px 0 0;
  }
}
.LogoTop {
  display: block;
  width: 82px;
  height: (89.49 / 234.99) * 82px;
  margin: 0 auto 64px;

  @include respond-to('xl') {
    width: 90px;
    height: (89.49 / 234.99) * 90px;
    margin: 0 auto 69px;
  }
}
.QrCode {
  display: block;
  width: 114px;
  height: 114px;
  margin: 0 auto 20px;

  @include respond-to('xl') {
    width: 127px;
    height: 127px;
    margin: 0 auto 24px;
  }
}
.Heading {
  margin: 0 auto 17px;
  font-size: 44px;
  text-transform: uppercase;
  @include font-graphik-wideblack;

  @include respond-to('xl') {
    margin: 0 auto 18px;
    font-size: 49px;
  }
}
.Copy {
  width: 260px;
  margin: 0 auto 20px;
  font-size: 11px;
  line-height: 1.2;

  @include respond-to('xl') {
    width: 320px;
    margin: 0 auto 20px;
    font-size: 13px;
  }
}
.Button {
  display: inline-block;
  padding: 6px 9px;
  color: $color-white;
  font-size: 11px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $color-red-orange;
  border: 3px solid $color-red-orange;
  transition: background-color 300ms ease;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  @include font-graphik-wideblack;

  &:active {
    transition: background-color 100ms ease;
    color: $color-red-orange;
    background-color: transparent;
  }

  @include respond-to('xl') {
    padding: 6px 9px;
    font-size: 12px;
  }
}
.LogoBottom {
  display: block;
  width: 38px;
  height: (94.46 / 115.88) * 38px;
  margin: 46px auto 12px;

  @include respond-to('xl') {
    width: 42px;
    height: (94.46 / 115.88) * 42px;
    margin: 53px auto 12px;
  }
}
.CopyBottom {
  margin: 0 auto 0px;
  color: $color-white;
  font-size: 16px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  @include font-graphik-wideblack;

  @include respond-to('xl') {
    margin: 0 auto 0px;
    font-size: 18px;
  }
}

.Social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a {
    display: inline-block;
    padding: 10px 10px;

    img {
      display: block;
    }
  }

  @include respond-to('xl') {
    a {
      padding: 10px 11px;
    }
  }
}
$social-icon-width: 19px;
$social-icon-width-xl: 21px;
.SocialIconInstagram {
  width: $social-icon-width;
  height: (56.53 / 56.57) * $social-icon-width;

  @include respond-to('xl') {
    width: $social-icon-width-xl;
    height: (56.53 / 56.57) * $social-icon-width-xl;
  }
}
.SocialIconTwitter {
  width: $social-icon-width;
  height: (46.17 / 56.57) * $social-icon-width;

  @include respond-to('xl') {
    width: $social-icon-width-xl;
    height: (46.17 / 56.57) * $social-icon-width-xl;
  }
}
.SocialIconFacebook {
  width: $social-icon-width;
  height: (1357 / 1365) * $social-icon-width;

  @include respond-to('xl') {
    width: $social-icon-width-xl;
    height: (1357 / 1365) * $social-icon-width-xl;
  }
}
