@import 'styles/common';

.ModalMapZoomed {
  width: 100%;
  height: 100%;
  background-color: $color-green-map-bg;
  overflow: auto;
}

.MapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 2vw;
}
.MapContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.Map {
  display: block;
  width: 400vw;
  height: auto;
}
.MapPin {
  position: absolute;
  top: 0;
  left: 0;
}
.MapPinInner {
  position: relative;

  img {
    $pin-width: 3.5vw * 4;
    display: block;
    width: $pin-width;
    height: (189.55 / 168.1) * $pin-width;
  }
  div {
    $animation-offset: 500ms;
    $circle-size: 12vw * 4;
    position: absolute;
    top: -2.2vw * 4;
    left: -3.3vw * 4;
    display: block;
    width: $circle-size;
    height: $circle-size;
    background-color: transparent;
    border: 3px * 4 solid $color-green-bright;
    border-radius: $circle-size;
    animation-duration: ($animation-offset * 5);
    animation-name: mapPinCircle;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @for $i from 1 through 5 {
      &:nth-child(#{$i + 1}) {
        animation-delay: $i * -1 * $animation-offset;
      }
    }
  }
}
@keyframes mapPinCircle {
  0% {
    transform: scale(0.0);
    opacity: 1;
  }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}
